var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ContextSource } from './contextSource';
var Peptide = /** @class */ (function (_super) {
    __extends(Peptide, _super);
    function Peptide(id, name, sequence, abbreviated, mz, charge, apiKey, traceColor, shadeGrade) {
        var _this = _super.call(this, id, name, abbreviated, apiKey, traceColor, shadeGrade) || this;
        _this.sequence = sequence;
        _this.mz = mz;
        _this.charge = charge;
        return _this;
    }
    return Peptide;
}(ContextSource));
export { Peptide };
