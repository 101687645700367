import { environment } from '../../environments/environment';
import { Stomp } from 'stompjs/lib/stomp.js';
import * as SockJS from 'sockjs-client';
import { Subject } from 'rxjs';
import { WebSocketNotification } from '../models/webSocketNotification';
import { AnnotationService } from './annotation.service';
import * as i0 from "@angular/core";
import * as i1 from "./annotation.service";
var WebsocketService = /** @class */ (function () {
    function WebsocketService(annotationService) {
        this.annotationService = annotationService;
        this.stompClient = null;
        this.dateRangeAllowNewData = true;
        this.nonConformities = new Subject();
        this.nonConformities$ = this.nonConformities.asObservable();
        this.dataFromWebSocket = new Subject();
        this.dataFromWebSocket$ = this.dataFromWebSocket.asObservable();
        this.thresholdFromWebSocket = new Subject();
        this.thresholdFormWebSocket$ = this.thresholdFromWebSocket.asObservable();
        this.newLabSystemFromWebSocket = new Subject();
        this.newLabSystemFromWebSocket$ = this.newLabSystemFromWebSocket.asObservable();
        this.newAnnotationFromWebSocket = new Subject();
        this.newAnnotationFromWebSocket$ = this.newAnnotationFromWebSocket.asObservable();
        this.updateAnnotationFromWebSocket = new Subject();
        this.updateAnnotationFromWebSocket$ = this.updateAnnotationFromWebSocket.asObservable();
        this.deleteAnnotationFromWebSocket = new Subject();
        this.deleteAnnotationFromWebSocket$ = this.deleteAnnotationFromWebSocket.asObservable();
        this.updateMessageFromWebSocket = new Subject();
        this.updatemessagefromwebsocket$ = this.updateMessageFromWebSocket.asObservable();
        this.enableDisableLS = new Subject();
        this.enableDisableLS$ = this.enableDisableLS.asObservable();
        this.updateIntranet = new Subject();
        this.updateIntranet$ = this.updateIntranet.asObservable();
        this.updateDashboard = new Subject();
        this.updateDashboard$ = this.updateDashboard.asObservable();
        this.updateSharedViews = new Subject();
        this.updateSharedViews$ = this.updateSharedViews.asObservable();
        this.apiPrefix = environment.apiPrefix;
        this.websocketUrl = this.apiPrefix + 'api/gs-guide-websocket';
        this.connectionAttemps = 0;
        this.connectWebsocket();
    }
    WebsocketService.prototype.manageWebSocketMessage = function (actionFromWebSocket, apiKey, qccv, body) {
        var action = this.getActionFromActionFromWebSocket(actionFromWebSocket);
        var actionValue = this.getActionValueFromActionWebSocket(actionFromWebSocket);
        switch (action) {
            case 'nc':
                this.nonConformities.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'data':
                if (this.dateRangeAllowNewData) {
                    this.dataFromWebSocket.next(new WebSocketNotification(actionValue, apiKey, qccv, body));
                }
                break;
            case 'threshold':
                if (this.dateRangeAllowNewData) {
                    this.thresholdFromWebSocket.next(new WebSocketNotification(actionValue, apiKey, qccv, body));
                }
                break;
            case 'labsystem':
                this.newLabSystemFromWebSocket.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'annotation':
                this.newAnnotationFromWebSocket.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'deleteannotation':
                this.deleteAnnotationFromWebSocket.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'updateannotation':
                this.updateAnnotationFromWebSocket.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'message':
                this.updateMessageFromWebSocket.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'enableDisableLS':
                this.enableDisableLS.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'fileIntranet':
                this.updateIntranet.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'fileDashboard':
                this.updateDashboard.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            case 'updateCustomViews':
                this.updateSharedViews.next(new WebSocketNotification(actionValue, null, null, body));
                break;
            default:
                console.log(actionFromWebSocket);
                break;
        }
    };
    WebsocketService.prototype.connectWebsocket = function () {
        var _this_1 = this;
        var _this = this;
        console.log('connecting...');
        if (this.stompClient === null) {
            _this.connectionAttemps++;
            var socket = new SockJS(_this.websocketUrl);
            var stompClient_1 = Stomp.over(socket);
            stompClient_1.debug = function (f) { return f; }; // disable stomp client debug log
            this.stompClient = stompClient_1;
            var headers = {
                Authorization: localStorage.getItem('id_token')
            };
            stompClient_1.connect(headers, function (frame) {
                console.log('connected');
                _this.connectionAttemps = 0;
                stompClient_1.subscribe('/user/queue/reply', function (greeting) {
                    var response = JSON.parse(greeting.body);
                    _this.manageWebSocketMessage(response['action'], response['apiKey'], response['qccv'], response['object']);
                });
            }, function () {
                console.log('Disconnected, trying to reconnect...');
                setTimeout(function () {
                    _this_1.stompClient = null;
                    if (_this.connectionAttemps < 5) {
                        _this_1.connectWebsocket();
                    }
                    else {
                        console.log('Max number of connection attemps');
                    }
                }, 5000);
            });
        }
    };
    WebsocketService.prototype.getActionFromActionFromWebSocket = function (actionFromWebSocket) {
        return actionFromWebSocket.substring(0, actionFromWebSocket.indexOf('-'));
    };
    WebsocketService.prototype.getActionValueFromActionWebSocket = function (actionFromWebSocket) {
        return actionFromWebSocket.substring(actionFromWebSocket.indexOf('-') + 1);
    };
    WebsocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebsocketService_Factory() { return new WebsocketService(i0.ɵɵinject(i1.AnnotationService)); }, token: WebsocketService, providedIn: "root" });
    return WebsocketService;
}());
export { WebsocketService };
