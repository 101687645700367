import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./../application/application.module.ngfactory").then(function (m) { return m.ApplicationModuleNgFactory; }); }, ɵ1 = function () { return import("./../entry-point/entry-point.module.ngfactory").then(function (m) { return m.EntryPointModuleNgFactory; }); };
var routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'application', loadChildren: ɵ0 },
    { path: 'login', loadChildren: ɵ1 },
    { path: '**', redirectTo: '/login', pathMatch: 'full' }
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1 };
