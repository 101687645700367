import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserDefaultViewService = /** @class */ (function () {
    function UserDefaultViewService(httpClient) {
        this.httpClient = httpClient;
        this.apiPrefix = environment.apiPrefix;
        this.userDefaultViewUrl = this.apiPrefix + 'api/userdefaultview';
    }
    UserDefaultViewService.prototype.findDefaultView = function () {
        return this.httpClient.get(this.userDefaultViewUrl);
    };
    UserDefaultViewService.prototype.saveUserDefaultView = function (userDefaultView) {
        var json = JSON.stringify(userDefaultView);
        var params = json;
        var headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.post(this.userDefaultViewUrl, params, { headers: headers });
    };
    UserDefaultViewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserDefaultViewService_Factory() { return new UserDefaultViewService(i0.ɵɵinject(i1.HttpClient)); }, token: UserDefaultViewService, providedIn: "root" });
    return UserDefaultViewService;
}());
export { UserDefaultViewService };
