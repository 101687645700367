import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LinkService = /** @class */ (function () {
    function LinkService(httpClient) {
        this.httpClient = httpClient;
        // The url prefix
        this.apiPrefix = environment.apiPrefix;
        // The message URL
        this.linkUrl = this.apiPrefix + 'api/link';
    }
    /**
     * @summary Sends a GET petition to get all logos
     * @author Marc Serret
     * @since 1.0.0
     * @returns All the logos
     * @access public
     */
    LinkService.prototype.getAllLinks = function () {
        return this.httpClient.get(this.linkUrl);
    };
    LinkService.prototype.getByApiKey = function (apiKey) {
        return this.httpClient.get(this.linkUrl + "/" + apiKey);
    };
    LinkService.prototype.updateLink = function (link) {
        var json = JSON.stringify(link);
        var params = json;
        var headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.post(this.linkUrl + '/update', params, { headers: headers });
    };
    LinkService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkService_Factory() { return new LinkService(i0.ɵɵinject(i1.HttpClient)); }, token: LinkService, providedIn: "root" });
    return LinkService;
}());
export { LinkService };
