import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PasswordResetService = /** @class */ (function () {
    function PasswordResetService(httpClient) {
        this.httpClient = httpClient;
        this.apiPrefix = environment.apiPrefix;
        this.passwordResetUrl = this.apiPrefix + 'api/passwordreset';
    }
    PasswordResetService.prototype.checkPasswordResetToken = function (token) {
        return this.httpClient.get(this.passwordResetUrl + '/check/' + token);
    };
    PasswordResetService.prototype.askForPasswordReset = function (user) {
        var json = JSON.stringify(user);
        var params = json;
        var headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.post(this.passwordResetUrl, params, { headers: headers });
    };
    PasswordResetService.prototype.saveNewPassword = function (user, token) {
        var json = JSON.stringify(user);
        var params = json;
        var headers = new HttpHeaders().set('Content-type', 'application/json');
        return this.httpClient.put(this.passwordResetUrl + '/reset/' + token, params, { headers: headers });
    };
    PasswordResetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordResetService_Factory() { return new PasswordResetService(i0.ɵɵinject(i1.HttpClient)); }, token: PasswordResetService, providedIn: "root" });
    return PasswordResetService;
}());
export { PasswordResetService };
