import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NodeIntranetService = /** @class */ (function () {
    function NodeIntranetService(httpClient) {
        this.httpClient = httpClient;
        // The url prefix
        this.apiPrefix = environment.apiPrefix;
        // The message URL
        this.nodeIntranetUrl = this.apiPrefix + 'api/intranet/node';
    }
    NodeIntranetService.prototype.getAllNodes = function () {
        return this.httpClient.get(this.nodeIntranetUrl + "/getAll");
    };
    NodeIntranetService.prototype.getNodeByApiKey = function (apiKey) {
        var params = new HttpParams();
        params = params.set('apiKey', apiKey);
        return this.httpClient.get(this.nodeIntranetUrl + "/get", { params: params });
    };
    NodeIntranetService.prototype.getLabsystemsByNodeApiKey = function (nodeApiKey) {
        var params = new HttpParams().set('apiKey', nodeApiKey);
        return this.httpClient.get(this.nodeIntranetUrl + "/getLS", { params: params });
    };
    NodeIntranetService.prototype.getLsStats = function (lsApiKey) {
        var params = new HttpParams().set('apiKey', lsApiKey);
        return this.httpClient.get(this.nodeIntranetUrl + "/stats", { params: params });
    };
    NodeIntranetService.prototype.getLs = function (lsApiKey) {
        var params = new HttpParams().set('apiKey', lsApiKey);
        return this.httpClient.get(this.nodeIntranetUrl + "/system", { params: params });
    };
    NodeIntranetService.prototype.getNodeByLsApiKey = function (lsApiKey) {
        var params = new HttpParams().set('apiKey', lsApiKey);
        return this.httpClient.get(this.nodeIntranetUrl + "/getNodeByLsApiKey", { params: params });
    };
    NodeIntranetService.prototype.getNodeStats = function (nodeApiKey) {
        var params = new HttpParams().set('apiKey', nodeApiKey);
        return this.httpClient.get(this.nodeIntranetUrl + "/statsNode", { params: params });
    };
    NodeIntranetService.prototype.getGeneralStats = function () {
        return this.httpClient.get(this.nodeIntranetUrl + "/generalStats");
    };
    NodeIntranetService.prototype.getHomePageStats = function () {
        return this.httpClient.get(this.nodeIntranetUrl + "/homePageStats");
    };
    NodeIntranetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NodeIntranetService_Factory() { return new NodeIntranetService(i0.ɵɵinject(i1.HttpClient)); }, token: NodeIntranetService, providedIn: "root" });
    return NodeIntranetService;
}());
export { NodeIntranetService };
